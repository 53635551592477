<template>
  <div>
    <Preloader v-if="loading"/>
    <b-form class='row'>

      <b-form-group label="От:" class="col-6" label-for="input-date_from">
        <b-form-datepicker id="input-date_from"
                            v-model="date_from"
                            :value="date_from"
                            :data-date-format="'mm.dd.yyyy'"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            locale="ru"
                            @input='getStats'
                            required>
        </b-form-datepicker>
      </b-form-group>

      <b-form-group label="По:" class="col-6 mb-5" label-for="input-date_to">
        <b-form-datepicker id="input-date_to"
                            v-model="date_to"
                            :value="date_to"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            locale="ru"
                            @input='getStats'
                            required>
        </b-form-datepicker>
      </b-form-group>

    </b-form>

    <div v-if="stats && Object.keys(stats).length > 0">
      <table class='table table-hover'>
        <thead class='table_head'>
        <tr class="text-center font-weight-bold">
          <th>Кол-во преподавателей</th>
          <th>Кол-во учеников</th>
          <th>Кол-во заявок</th>
          <th>Кол-во пробных</th>
          <th>Кол-во пробных завершённых</th>
          <th>Кол-во пробных пропущенных</th>
          <th>Соотношение пробных завершённых</th>
          <th>Соотношение пробных пропущенных</th>
          <th>Кол-во уроков в абонементах</th>
        </tr>
        </thead>
        <tbody>
          <tr class="text-center">
            <td>{{ stats.count_teachers }}</td>
            <td>{{ stats.count_students }}</td>
            <td>{{ stats.count_leads }}</td>
            <td>{{ stats.all_trial_lessons }}</td>
            <td>{{ stats.all_finished_trial_lessons }}</td>
            <td>{{ stats.all_skipped_trial_lessons }}</td>
            <td>{{ stats.ratio_finished_trial_lessons_by_trial_lessons }}&#37;</td>
            <td>{{ stats.ratio_skipped_trial_lessons_by_trial_lessons }}&#37;</td>
            <td>{{ stats.student_products_date_to }} / {{stats.student_products}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="widget" v-else>
      <p>Результатов не найдено</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Preloader from "../../../components/Preloader";

export default {
  name: "StatsListSchool",
  components: { Preloader},
  data() {
    return {
      date_from: moment().startOf('month').format('YYYY-MM-DD'),
      date_to: moment().endOf('month').format('YYYY-MM-DD'),
      stats: null,
      loading: false,
    }
  },
  methods: {
    async getStats() {
      this.loading = true;

      const from = moment(this.date_from).format('DD.MM.YYYY');
      const to = moment(this.date_to).format('DD.MM.YYYY');

      let params = {
        from,
        to
      }

      try {
        const { data } = await this.$axios.get(`/api/stats/school`, { params });

        this.stats = data.data;

        this.loading = false;
      }catch (e) {
        this.loading = false;
        console.log(`Error get statistics teacher: ` . e)
      }
    },
  },
  async created() {
    await this.getStats()
  }
}
</script>

<style lang="scss">
</style>
